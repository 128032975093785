
import './App.css';
import AllComponents from './Components/AllComponens/AllComponents';
import UserDetailsContext from './Context/UserdetailsContext'
function App() {
  return (
   <div>
     <UserDetailsContext>
     <AllComponents/>
     </UserDetailsContext>
   </div>
  );
}
export default App;
