import React from 'react'
import './SignInComponent.css'

import IndivSignInComponent from './IndivSignInComponent/IndivSignInComponent'
export default function SignInComponent() {
    return (
        
                
            
        <div >
        <div style={{marginTop:"8vh"}}>
            <IndivSignInComponent/>
        </div>
    </div>
      
    )
}

