import {  message, Spin } from 'antd';
import React,{createContext,useState,useEffect,useRef} from 'react'
export const UserDetailsContext=createContext()
export default function UserDetailsContextProvider({children}) {
    const [loading,setLoading]=useState(true)
    const [accessToken, setAccessToken] = useState("");
    const [pageMain,setPageMain]=useState('login')
    // const [accessToken, setAccessToken] = useState('eyJraWQiOiJcL3dYY29meGFtd3dsblh6Mklud2xNTzE3Q3hjOTVnSnNNbjR0OUwwQzV4UT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI3MGI5ZmNlMC0wZDBlLTQxOGYtYmFjNS02ZDNiYzMyYTMwNTQiLCJldmVudF9pZCI6IjJiODk3NzBlLTE5YjItNDc0Ni05OGUzLWM4M2NhZTI5YzdjZSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2Mjk5NTUwMzgsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoLTFfZkpOWHg2b0p0IiwiZXhwIjoxNjI5OTU4NjM4LCJpYXQiOjE2Mjk5NTUwMzgsImp0aSI6IjgwN2FlZTUwLTdhY2QtNDMxNC1iODVlLWQ4ZmFlNGU1NjIxMSIsImNsaWVudF9pZCI6Imo2cGg4c2swZ2Y2MWFsbzFtYnZmYTF2OTkiLCJ1c2VybmFtZSI6ImpheXNvbmZpbm55OTMifQ.huOqMOAL2TRrgUKNmF4WdSKxrrJXCi2g9Bxc4_6ycfy4fsWMeg3kJ6BS7BvB92cWCh3rL9rG85HQom4jidfKgtyt6YUU3cJyvHhqcR4YvtLomJpIKfguBb-zJPXyDNWj3k1g-4djJ6rl6VuU6ipv2HqcmNposCUQ3XQ7zlUdo_GzJhPYPS7KEgafYxw0DC4o4_rTEyXYX4PNQ8i3rinJ1KeOmmOkFhMJ0jT7JXbvMAn57A0CaOnVNR-lH9TffkeQmCklkLbB7KAbuQ6DAEC7bxwU2MksfvqTK-WOk6Ppz5MJ2J5tEd6wNZAjAQvMzenycq7qp8TdF30DY_1tJb9O9w');
    const [userSub,setUserSub]=useState('');
    // const newApi=`https://57mpla6pti.execute-api.ap-south-1.amazonaws.com/v2`
    // const secondApi = `https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/dev`
    // const newApi=`https://57mpla6pti.execute-api.ap-south-1.amazonaws.com/dev`
    // const appName="OBO_CLOUD_STORAGE_DEV"
    // const secondAppName = "OBO_CLOUD_APP_DEV"
    const secondApi = `https://pnre7lwmvk.execute-api.ap-south-1.amazonaws.com/v1`
    const newApi=`https://57mpla6pti.execute-api.ap-south-1.amazonaws.com/v1`
    const appName="OBO_CLOUD_STORAGE"
    const secondAppName ="OBO_CLOUD_APP"
    //Authenticate User
    // const mounted = useRef(false);
    useEffect(() => {
      if (window.location.pathname.slice(1)) {
        var raw = JSON.stringify({
          appName: secondAppName,
          modName: "GET_LONG_URL",
          elementId:"shortener1",
          shortUrl:window.location.pathname.slice(1)
        });
        var requestOptions = {
          method: 'POST',
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` },
          body: raw,
          redirect: 'follow'
        };
        fetch(`${secondApi}/general`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.statusCode === 200) {
             window.location.href=result.body
            }
          })
      }
      else{
        setAccessToken(localStorage.getItem('accessToken') || '')
        // setAccessToken("eyJraWQiOiJTM1VKbk15ZXR1NFZGeVVmeUxQZjljK281eWFFelNqWE8zTVZjdmdZazFZPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIwYmZlMzljYi01YmQyLTQ4MTMtYWQ0NC1kMWU5YTgzYmYxYjYiLCJldmVudF9pZCI6ImRiMWM5ZDFkLTg3ZmUtNDk5Yy04NjFhLTNiZGFkMDdlNWE1NSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2NjA2MzE4NzAsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoLTFfRFhqYjRaWUtWIiwiZXhwIjoxNjYwNjM1NDcwLCJpYXQiOjE2NjA2MzE4NzAsImp0aSI6IjkzZWNhOGFhLWIyYWItNDA2OC1iNjE0LTUzY2NlMGM2MjFmMyIsImNsaWVudF9pZCI6Ijdnb2lmdnNnZXVhZTI5YmNiN2ptbTdvdTRuIiwidXNlcm5hbWUiOiJqYXlzb25maW5ueTkzIn0.hCBWCVxLdp1e3La13-F7OcScstQ-Ga1RPT6ImdTbgANCx1pFFeqIhg4p6HWopg77wz8gjqxGfICFaA4I2lXilTfhQmUY_dBd5qbnbKbWVF_p4F0eYST7iCOxhfExx56jMp329VQe-_YyOUSTzWMjhxnFWhWXadiT6DAddHRSLhPmVu7Z4Eza_yiB65v1lgzQuAJJG-ycrjzlJWPLsR1Wa4tI8kwr2GjIWgcTqrRjjokY5XTqlTSdRuonqApF4v2TYO3as9MmOyzT3azfmXp8ZgAzdNGU1RHnoGQ66BLGSIODV1-U6Nv5-MsoIccYdHkZFqh84s-Kny2BEfQPFJKgeA")
      }
    },[])
    useEffect(()=>{
        if(accessToken!==''){
          var raw = JSON.stringify({"appName":appName});
          var requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json","Authorization":`Bearer ${accessToken}`},
            body: raw,
            redirect: 'follow'
          };
          fetch(`${newApi}/validate`,requestOptions)
            .then(response => response.json())
            .then(result => {
              if(result.statusCode===200){
                //window.location.href=(`https://www.obocloudstore.com/dashboard/index.html`)
                //window.location.href=(`https://obocloudstore.com/dashboard/index.html`)
                 window.location.href=(`/dashboard/index.html`)
              }
              else{
                localStorage.removeItem('accessToken')
                setAccessToken('')
                setLoading(false)
              }
            })
            .catch(error => {
            });
            }
            else {
                setLoading(false)
            }
    },[accessToken])
    if(loading){
      return<Spin className="text-center w-100"/>
    }
    return(
        <UserDetailsContext.Provider value={{pageMain,setPageMain,appName,newApi}}>
            {children}
        </UserDetailsContext.Provider>
    )
}
