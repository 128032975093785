import React from 'react'
import './HeaderComponent.css'
export default function HeaderComponent() {
        const goToHome=()=>{
            window.location.href='/'
        }
        return (
            <div className="container-fluid d-flex justify-content-between align-items-center pl-5 pt-5">
                <div className="header-title font-color pointer" onClick={goToHome}>OBO CLOUD STORE</div>
                <div className="sign-in-title font-color pointer"></div>
            </div>
        )
    
}
