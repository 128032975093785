import React, { useState } from 'react'
import { Modal } from 'antd';
import './FooterComponent.css'
import termsand from '../../asset/terms/OBO Cloud Storage.pdf'
import refund from '../../asset/terms/Refund.pdf'
import privacy from '../../asset/terms/Privacy Policy.pdf'
import { Link } from 'react-router-dom';
export default function FooterComponent({adModal}) {

  const [showModal, setShowModal] = useState(false)
  return (
    <div style={{position:adModal?"relative":"absolute"}} className="footer-style" >
      <div className="container-fluid pr-md-5 row ">
        {/*<div className="col-4 col-md-2 " style={{color:"white"}}>ABOUT US</div>*/}
        {/*<div className="col-4 col-md-2 " style={{color:"white"}}>PRIVACY POLICY</div>*/}
        <div className="col-12 col-md-5 row ml-1 mt-5" >
          <div className=''><a href={termsand} target="_blank" rel="noreferrer" style={{ color: "white", cursor: "pointer", lineHeight: 2 }} >TERMS OF USE </a><span className='ml-3 mr-3 text-white'>|</span></div>
          {/* <div className=''><Link className=' text-white' to='/aboutus' style={{ cursor: "pointer", lineHeight: 2 }} >ABOUT US</Link><span className='ml-3 mr-3 text-white'>|</span></div> */}
          <div className=''><a className=' text-white' href={refund} target="_blank" rel="noreferrer" style={{ color: "white", cursor: "pointer", lineHeight: 2 }}>REFUND POLICY</a><span className='ml-3 mr-3 text-white'>|</span> </div>
          <div className=''><a className=' text-white' href={privacy} target="_blank" rel="noreferrer" style={{ color: "white", cursor: "pointer", lineHeight: 2 }}>PRIVACY POLICY</a> <span className='ml-3 mr-3 text-white'>|</span></div>
          <div className=''><span className=' text-white' onClick={()=>window.location.href='/contact/index.html'} rel="noreferrer" style={{ color: "white", cursor: "pointer", lineHeight: 2 }}>CONTACT US</span> </div>
        </div>
        <div className=" col-md-1 " style={{ color: "white" }}></div>
        <div className="col-12 col-md-6 " style={{ color: "white" }}><div className="footer-end">All Rights Reserved | Obo Cloud Store</div></div>
      </div>
    </div>
  )
}
