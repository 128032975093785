import React, { useState,useContext } from 'react'
import './GetCodeComponent.css'
import { Form, Input, Button, Alert } from 'antd';
import {LockOutlined,MailOutlined,CodeOutlined   } from '@ant-design/icons';
import {UserDetailsContext} from '../../../Context/UserdetailsContext'
export default function GetCodeComponent({goToPage,goToSuccess}) {
    const {pageMain,setPageMain,appName,newApi}=useContext(UserDetailsContext)
    const [userEmail,setUserEmail]=useState('')
    const [page,setPage]=useState('code')
    const [error,setError]=useState('')
    const [success,setSuccess]=useState('')
    const [backcolor,setBackcolor]=useState("white")
    const onEmailFinish = (values) => {
        setUserEmail(values.email);
        var raw = JSON.stringify({"appName":appName,"useremail":values.email});
        var requestOptions = {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: raw,
        redirect: 'follow'
        };
        fetch(`${newApi}/forgot-password`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.statusCode===200){
                setError('')
                setSuccess('You will get an OTP if you are already registered with us. Check Primary/Promotion/Spam in your email. Copy that & paste it here.')
                setPage('email')
            }
            if(result.statusCode===500){
                setSuccess('')
                setError("couldn't reach server")
            }
        })
        .catch(error => {
            setSuccess('')
            setError("couldn't reach server")
        });
      };
      const onCodeFinish=(values)=>{
        const password=values.password
        const re_password=values.re_password
          if(password !== re_password){
              setError("Password doesn't match")
              return;
          }
          var raw = JSON.stringify({"appName":appName,"useremail":userEmail,"confirm_code":values.code,"password":password});
          var requestOptions = {
          method: 'POST',
          headers: {"Content-Type": "application/json"},
          body: raw,
          redirect: 'follow'
          };
          fetch(`${newApi}/verify-password`, requestOptions)
          .then(response => response.json())
          .then(result => {
              if(result.statusCode===200){
                  setError('')
                  goToSuccess('Your Password have been changed Successfully')
                  goToPage('login')
              }
              if(result.statusCode===500){
                  setSuccess('')
                  setError("Invalid OTP")
              }
          })
          .catch(error => {
              setSuccess('')
              setError("couldn't reach server")
          });
      }
      const onResendCode=()=>{
            setError('')
            var raw = JSON.stringify({"appName":appName,"useremail":userEmail});
            var requestOptions = {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: raw,
            redirect: 'follow'
            };
            fetch(`${newApi}/forgot-password`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result.statusCode===200){
                    setError('')
                    setSuccess('Code has been re-send to your E-mail')
                }
                if(result.statusCode===500){
                    setSuccess('')
                    setError("couldn't reach server")
                }
            })
            .catch(error => {
                setSuccess('')
                setError("couldn't reach server")
            });
      }
    const CodeComp=()=>{
        return <div className="pt-5">
        {error&&<Alert message={error} type="error" showIcon className="mb-2"/>}
        {success&&<Alert message={success} type="success" showIcon className="mb-2"/>}
        <Form name="signin"initialValues={{remember: true,}}onFinish={onCodeFinish}>
            <Form.Item name='code' rules={[{ required:true,message: 'Please enter your Code!' }]}className="t form1-style">
                <Input placeholder="Code"  className="input-style"/>
            </Form.Item><div className="icon-div">Code</div>
            <Form.Item name="password" rules={[{required: true,message: 'Please enter your Password!',},]} className="t form1-style">
                <Input.Password placeholder="Password" id="codepass" onBlur={()=>{setBackcolor("white")}} style={{backgroundColor:backcolor}}  className="input-style"/>
            </Form.Item><div className="icon-div">Enter new password</div>
            <Form.Item name="re_password"rules={[{required: true,message: 'Please re-enter your Password!',},]} className="t form1-style">
                <Input.Password placeholder="Re-Enter Password" id="coderepass"  onBlur={()=>{setBackcolor("white")}} style={{backgroundColor:backcolor}}  className="input-style"/>
            </Form.Item><div className="icon-div">Re-enter password</div>
            <Form.Item >
            <Button type="primary" htmlType="submit" style={{color:"white"}}className="button4-style">
                Change Password
            </Button>
            </Form.Item>
            </Form>
            <div className="forgot-text forgot-password-1" style={{cursor:'pointer', color:"lavender",marginTop:"1vh"}} onClick={onResendCode}>Resend OTP</div>
            <div className="notmember" style={{color:"white",cursor:"pointer"}}  onClick={()=>setPageMain("login")}> {"<<"}Back to login page</div>
</div>
    }
    const EmailComp=()=>{
        return <div className="  pt-5">
        {error&&<Alert message={error} type="error" showIcon className="mb-2"/>}
       
    <Form name="signin"initialValues={{remember: true,}}onFinish={onEmailFinish}>
        <Form.Item name='email' rules={[{ type: 'email',required:true,message: 'Please enter the E-mail!' }]}className="t form1-style">
            <Input   className="input-style"/>
        </Form.Item><div className="icon-div">Email Address</div>
        <Form.Item >
            <Button type="primary" htmlType="submit" style={{color:"#001e29",color:"white"}}className="button4-style">
                Get Code
            </Button>
        </Form.Item>
        </Form>
        <div className="notmember" style={{color:"white",cursor:"pointer"}}  onClick={()=>setPageMain("login")}> {"<<"}Back to login page</div>
</div>
    }
    return (
       <>
       {page==='email'&&CodeComp()}
       {page==='code'&&EmailComp()}
       </>
    )
}
