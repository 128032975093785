import React, { useEffect, useState,useContext } from 'react'
import './IndivSignInComponent.css'
import { Form, Input, Button, Spin, Alert,Modal,Checkbox} from 'antd';
import GetCodeComponent from '../GetCodeComponent/GetCodeComponent';
import {UserOutlined,LockOutlined,MailOutlined  } from '@ant-design/icons';
import {UserDetailsContext} from '../../../Context/UserdetailsContext'
import termsand from '../../../asset/terms/OBO Cloud Storage.pdf'
export default function IndivSignInComponent() {
    const {pageMain,setPageMain,appName,newApi}=useContext(UserDetailsContext)
    const [loading,setLoading]=useState(false)
    const [error,setError]=useState('')
    const [success,setSuccess]=useState('')
    const [forgotSuccess,setForgotSuccess]=useState('')
    const [regSuccess,setRegSuccess]=useState(true)
    const [backcolor,setBackcolor]=useState("white")
    const [showModal,setShowModal]=useState(false)
    const [email,setEmail]=useState(localStorage.getItem('Remember') || '')
    // const [check,setCheck]=useState(false)
    useEffect(()=>{
        setError('')
    },[pageMain])
   
    const onLoginFinish = (values) => {
        // console.log(values)

if(values.rememberme===true){
    localStorage.setItem('Remember',values.username)
}
         setError('')
         setLoading(true)
         var raw = JSON.stringify({"appName":appName,"username":values.username.replace(/\s/g, ""),"password":values.password});
             var requestOptions = {
             method: 'POST',
             headers: {"Content-Type": "application/json"},
             body: raw,
            redirect: 'follow'
             };
             fetch(`${newApi}/sign-in`, requestOptions)
             .then(response => response.json())
             .then(result => {
                 if(result.statusCode===200){
                     localStorage.setItem('accessToken',result.body.AuthenticationResult.AccessToken)
                     localStorage.setItem('idToken',result.body.AuthenticationResult.IdToken)
                     localStorage.setItem('refreshToken',result.body.AuthenticationResult.RefreshToken)
                     localStorage.setItem('userId',values.username)
                //    window.location.href=(`https://obocloudstore.com/dashboard/index.html`)
                //    window.location.href=(`https://www.obocloudstore.com/dashboard/index.html`)
                  window.location.href=(`/dashboard/index.html`)
                    setLoading(false)
                 }
                 if(result.statusCode===500){
                     setError(result.body.message)
                     setLoading(false)
                 }
               })
              .catch(error => {
                 setError("couldn't reach server")
                 setLoading(false) 
             });
      };
      const onRegisterFinish=(values)=>{
          console.log(values)
             setLoading(true)
             setSuccess('')
             setError('')
             if(values.password!==values.re_password){
             setLoading(false)
                 setError("Password doesn't match")
                 return;
             }
             var raw = JSON.stringify({"appName":appName,"username":values.email.replace("@","_"),"useremail":values.email,"password":values.password});
         var requestOptions = {
             method: 'POST',
         headers: {"Content-Type": "application/json"},
             body: raw,
             redirect: 'follow'
             };
             fetch(`${newApi}/sign-up`, requestOptions)
             .then(response => response.json())
             .then(result => {
                 if(result.statusCode===200){
                     setLoading(false)
                     setSuccess(`Account is Registered.\n Click the link send to ${values.email} to activate your account.Check Primary/Promotion/Spam in your Mail.`)
                     setRegSuccess(false)
                 }
                 if(result.statusCode===500){
                     setLoading(false)
                     setError(result.body.message)
                 }
             })
             .catch(error => {setError("couldn't reach server");setLoading(false)});
      }
    const SignInComponent=(setPageMain)=>{
        return <div className="pt-5">
                {error&&<Alert message={error} type="error" showIcon className="mb-2"/>}
                {forgotSuccess&&<Alert message={forgotSuccess} type="success" showIcon className="mb-2"/>}
                {loading&&<Spin/>}
                <Form name="signin"initialValues={{username:email}}onFinish={onLoginFinish}>
                    <Form.Item name="username" rules={[{type:'email',required: true,message: 'Please enter the Email!',}]}className="t form1-style">
                    <Input className="input-style" defaultValue={email} />
                    </Form.Item><div className="icon-div">Email Address</div>
                    <Form.Item name="password"rules={[{required: true,message: 'Please enter your password!',},]} className="t form1-style">
                        <Input.Password placeholder="Password" id="signpass" onBlur={()=>{setBackcolor("white")}} style={{backgroundColor:backcolor,}} className="input-style"/>
                    </Form.Item><div className="icon-div">Password</div>
                    <Form.Item  name='rememberme' valuePropName="checked" >
                <Checkbox className="check-stl" style={{float:"left",marginLeft:20}} ><span style={{color:"white",fontSize:18}}>Remember me</span></Checkbox>
                </Form.Item>
                <Form.Item>
              <div className="forgot-text forgot-password-1" onClick={e=>setPageMain('forgot')}>Forgot Password?</div>
              <div style={{display:"flex"}}><Button type="primary" htmlType="submit" style={{color:"white"}} className="button2-style">
                            Login
                        </Button>
                        <Button type="primary" style={{color:"#563ead"}} className="button3-style" onClick={()=>setPageMain("register")}>
                            Register
                        </Button></div>
                    </Form.Item>
                    </Form>

                   
            </div>
    }
    
    const RegisterComponent=(setPageMain)=>{
        return <div className="pt-5">
        {error&&<Alert message={error} type="error" showIcon className="mb-2"/>}
        {success&&<Alert message={success} type="success" showIcon className="mb-2"/>}
        {loading&&<Spin/>}
        {regSuccess&&<Form  name="register" onFinish={onRegisterFinish}>
        <Form.Item name="email" rules={[{type: 'email',required: true,message: 'Please enter the Email!',},]}className="t form1-style">
            <Input placeholder="E-mail" className="input-style" />
        </Form.Item><div className="icon-div">Email</div>
        <Form.Item name="password"rules={[{required: true,message: 'Please enter your password!',},]}className="t form1-style">
            <Input.Password placeholder="Password" id="regpass" onBlur={()=>{setBackcolor("white")}} style={{backgroundColor:backcolor}} className="input-style"/>
        </Form.Item><div className="icon-div">Password</div>
        <Form.Item name="re_password"rules={[{required: true,message: 'Please re-enter your password!',},]} className="t form1-style">
            <Input.Password placeholder="Confirm Password" id="regrepass" onBlur={()=>{setBackcolor("white")}} style={{backgroundColor:backcolor}} className="input-style"/>
        </Form.Item><div className="icon-div">Re-Enter password</div>
        <Form.Item name='termschecked' valuePropName="checked" rules={[{validator: (_, value) =>
               value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')), }, ]}>
                <Checkbox  ><a  href={termsand}  target="_blank" rel="noreferrer" style={{color:"white",cursor:"pointer"}} >Terms and conditions</a></Checkbox>
                </Form.Item>
                    <Form.Item >
            <Button type="primary" htmlType="submit" style={{color:"white"}}className="button4-style" >
                Register
            </Button>
        </Form.Item>
        </Form>}
        <div className="notmember" style={{color:"white",cursor:"pointer"}}  onClick={()=>setPageMain("login")}> {"<<"}Back to login page</div>
    </div>
       
    }
    return (
        <div className="indiv-sign-cont text-center mx-auto mb-4">
            <div className="d-flex justify-content-around" style={{color:"white"}}>
            </div>
            {pageMain==='register'&&<><div className="login-name">Register</div></>}
            {pageMain==='login'&&<><div className="login-content">Welcome! Please Login to your account</div></>}
            {pageMain==='login'&&SignInComponent(setPageMain)}
            {pageMain==='register'&&RegisterComponent(setPageMain)}
            {pageMain==='forgot'&&<GetCodeComponent goToPage={setPageMain} goToSuccess={setForgotSuccess}/>}
            <Modal className="modal-style" title="Terms and Conditions" bodyStyle={{height:"70vh",overflowY:"scroll"}} width="80%"  visible={showModal}  onCancel={e=>setShowModal(false)} onOk={e=>setShowModal(false)}>
                   <h3>TERMS AND CONDITIONS</h3>
<p>The terms and conditions constitute a legally binding agreement who use the service provided by OBO Technology private ltd. concerning your access to use the website or mobile application (<a href="https://www.obocloudstore.com" target="_blank">www.obocloudstore.com</a>) you shall be governed by following terms of use.</p>
<p><ul><li>You agree that by accessing the site by reading and understanding all terms and conditions. </li><li>If you don&rsquo;t agree our general terms you are requested by OBO Technology not to use the website.</li> <li>We reserve the right to change/modify these terms and conditions at any costs and any time.</li><li>So it is your responsibility to review these terms and conditions.</li></ul></p>
<h4>Description of OBO Cloud Storage</h4>
<p><strong>You&rsquo;re Content </strong>&ndash; This service allow the user to do CRUD Operations (Create, Read, Update and Delete). So we don&rsquo;t claim ownership on any of the content and files that you share and upload in OBO Cloud Storage you are responsible for that.<ul>
<li>Sharing content with other people they might have the copyright of the document what you share so they may able to share it on a worldwide basis. If you don&rsquo;t want to share your files with other, don&rsquo;t use this service to share content.</li>
<li>Any liability for your content the OBO will not takes responsible of the file you share.</li>
<li>We may review your content for compliance. So don&rsquo;t share your account credentials to others. </li>
<li>By accepting these terms and conditions you allowed to use OBO Cloud storage for personal as well as Business purpose.</li>
</ul></p>
<h4>Age Restrictions</h4>
<p>&ndash; To use our service you must be 13 years or older. If you are under 13 years you must have your parent or legal guardian&rsquo;s permission to use cloud storage and to accept the terms.<br></br> <b>You&rsquo;re Content</b> <br></br><ul>
<li>Don&rsquo;t do anything illegal.</li>
<li>Don&rsquo;t engage in activity that is fraudulent, false or misleading.</li>
<li>Don&rsquo;t engage that harm or liability to the user or OBO Cloud storage services.</li>
<li>Don&rsquo;t engage in violating the law or a legal authority</li>
</ul></p>
<h4>Removing your content</h4>
<p>&ndash; If you&rsquo;re content breaches these terms specified above, then we reserve the right to remove the account/content in accordance with applicable law. Example &ndash; Infringe other rights, harassment and threatens to harm children.</p>
<h4>Termination Account</h4>
<p>&ndash; You can stop using OBO Cloud Storage at any time, We are sad that you are leaving. We may disable or terminate the account that acts against law like Infringe, Harassment, Misleading Content, Sexually Explicit Material, threatens, child pornography, violence and terrorists activities.</p>
<h4>About and Updates to the services</h4>
<p>We can able to access the OBO Cloud storage remotely. It enables the user to do CRUD Operation (Create, Read, Update and Delete) located on your device from anywhere and for anytime in the world. We will not give ownership of any intellectual property rights in OBO Cloud Storage or the content you access. Update services &ndash; We are improving our functionalities or features in OBO Cloud storage. The changes we made to comply with law or illegal activities or abuse of our system.</p>
<h4>Limitations of Liability</h4>
<ul>
  <li>Losses that were not caused by breach of these terms.</li>
   <li>Loss of data stored on device or inability to access data. </li>
   <li>Losses relating to any business of yours (Loss/Profit).</li>
   <li>These terms don&rsquo;t limit liability for injury, fraud and death.</li>
   <li>Indirect, Incidental or consequential damage</li>
</ul>
<h4>Using Third-Party</h4>
<p>The services may allow to access the website who are not OBO Technology Private Ltd. (&ldquo;Third party services&rdquo;). This service makes request or Interact with Third-Party and also allow to share the content using our service. They cannot modify any Intellectual Property. Any risks or liability arising from third-party service the OBO will not be responsible for information provided by third-party services.</p>
<h4>Intellectual Property</h4>
<p>It will inform the user that the logo and other visual media you created is your property and is protected by copyright laws.</p>
<h4>Privacy Policy</h4>
<p>The functionality provide by OBO Technology like sharing, deleting &amp; restoring of files like word, pdf &amp; excel etc. We will not change a private document into public one of for marketing. You can also keep your data as long as you want. Privacy policy does not apply to &ndash; Service provide by OBO Technology cannot be advertise by any other organizations.</p>
<h4>Disclaimer</h4>
<p>All the information contained on this website www.obocloudstore.com is general information purpose only. It does not make any warranties about the completeness &amp; reliability of this information. We will not be liable for any losses or damage with use of our website. We reserve the right to make addition, deletion or modification to the service at any time without prior notice. So please be aware &amp; read all the terms &amp; services before engaging in our services.</p>
           
            </Modal>
        </div>
    )
}
