import { useState } from 'react';
import './ModalComponent.css'
export default function ModalComponent({adModal,setAdModal}) {
    
    return <div className='col-md-11 col-12 '>
        { adModal&&<div className="modal-ad col-12 d-flex justify-content-center mt-0 mt-md-5">
            <div className='modal-ad-in'>
            <div  style={{backgroundColor:"#fff"}}><div className='container pt-3 events-style'>Events Around You</div></div>
                <iframe  className='modal-ad-inside'   frameBorder="0" src="https://college-images-kumari.s3.ap-south-1.amazonaws.com/exhibition/subindex.html" title="description"></iframe>
            </div>
        </div>}
    </div>
}