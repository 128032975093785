import React,{useState} from 'react'
import HeaderComponent from '../HeaderComponent/HeaderComponent'
import SignInComponent from '../SignInComponent/SignInComponent'
import FooterComponent from '../FooterComponent/FooterComponent'
import ModalComponent from '../ModalComponent/ModalComponent'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUsComponent from '../AboutUsComponent/AboutUsComponent'
import video1 from '../../asset/images/1.mp4'
import './AllComponents.css'
export default function AllComponents() {
    // const back=`url("https://cdn.pixabay.com/photo/2020/12/30/11/00/syringe-5873159_640.jpg")`
    const [adModal,setAdModal]=useState(true)
    return (
        <div>

            <Router>
                <Routes>
                    <Route exact path="/" element={<>
                        <div className="back-image">
                            <div className="back-style">
                                <div style={{height:"100vh"}}> 
                                <HeaderComponent />
                                <SignInComponent />
                                </div>
                                <FooterComponent adModal={adModal}/>
                                
                            </div>
                           
                        </div>
                        <ModalComponent adModal={adModal} setAdModal={setAdModal}/>
                    </>} />
                   
                </Routes>
            </Router>

        </div>
    )
}
